import React from "react"
import CompanyItem from "./company-item";

const Company = () => {
    return (
        <div data-aos="fade-up" style={{
            background: "#fff",
            padding: "0 0 85px"
        }}>
            <div style={{
                margin: "auto",
                padding: `0 1.0875rem 0`,
                maxWidth: 960
            }}>
                <h2 style={{
                    fontSize: 18,
                    marginBottom: 34
                }}>会社概要</h2>
                <div>
                    <CompanyItem name={"会社名"} nameEnglish={"Company name"}>
                        <p>和風音</p>
                        <p>offon</p>
                    </CompanyItem>

                    <CompanyItem name={"本社所在地"} nameEnglish={"Headquarters"}>
                        <p>〒607-8253　京都市山科区小野弓田町21</p>
                        <p>21, Onoyumidencho, Yamashina-ku, Kyoto-shi, Kyoto 607-8253 JAPAN</p>
                    </CompanyItem>

                    <CompanyItem name={"代表者"} nameEnglish={"Representative"}>
                        <p>代表　小川修平</p>
                        <p>CEO Shuhei Ogawa</p>
                    </CompanyItem>

                    <CompanyItem name={"電話番号"} nameEnglish={"Phone number"}>
                        <p>075-573-2839</p>
                    </CompanyItem>

                    <CompanyItem name={"FAX番号"} nameEnglish={"FAX number"}>
                        <p>075-573-2840</p>
                    </CompanyItem>

                    <CompanyItem name={"事業内容"} nameEnglish={"Business"}>
                        <p>着物、和装小物の企画・製造・販売</p>
                        <p>和雑貨の企画・製造・販売</p>
                        <p>ジュエリー企画・製造・販売</p>
                    </CompanyItem>
                </div>
            </div>
        </div>
    )
}

export default Company
