/** @jsx jsx */
import { jsx } from "theme-ui"
import React from "react"
import {graphql, useStaticQuery} from "gatsby";
import Img from "gatsby-image";

const Wishes = () => {
    const data = useStaticQuery(graphql`
    query {
      title: file(name: { eq: "wishes-title" }) {
        childImageSharp {
          fluid(maxWidth: 770) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      wish1: file(name: { eq: "wish1" }) {
        childImageSharp {
          fluid(maxWidth: 277) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      wish2: file(name: { eq: "wish2" }) {
        childImageSharp {
          fluid(maxWidth: 249) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      wish3: file(name: { eq: "wish3" }) {
        childImageSharp {
          fluid(maxWidth: 221) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
    }
  `)

    return (
        <div data-aos="fade-up">
            <h2 style={{ marginTop: 85, marginBottom: 26 }}>
                <p style={{
                    letterSpacing: "4px",
                    color: "#fff",
                    fontSize: "18px",
                    textAlign: "center",
                    lineHeight: 1.5
                }}>わたしたち和風音は、3つの想い<span sx={{
                    "@media (max-width: 901px)": {
                        display: "block"
                    }
                }}>から新しいkimono文化を</span>創造します。</p>
            </h2>
            <div style={{margin: "15px auto 0 auto"}}>
                <div><Img fluid={data.wish1.childImageSharp.fluid} alt={"着物に受け継がれる伝統を次世代に継承する"} style={{margin: "auto", marginBottom: 43}} /></div>
                <div><Img fluid={data.wish2.childImageSharp.fluid} alt={"着物の持つ魅力をより多くの人に伝える"} style={{margin: "auto", marginBottom: 43}} /></div>
                <div><Img fluid={data.wish3.childImageSharp.fluid} alt={"着物と出会う機会を世界に発信する"} style={{margin: "auto"}} /></div>
            </div>
            <div id={"order"}/>
        </div>
    )
}

export default Wishes
