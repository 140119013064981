/** @jsx jsx */
import { jsx } from "theme-ui"
import React from "react"
import {graphql, useStaticQuery} from "gatsby";
import Img from "gatsby-image";

const RelatedAccounts = () => {
    const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          instagram
          line
        }
      }
      lineButton: file(name: { eq: "line-button" }) {
        childImageSharp {
          fluid(maxWidth: 528) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      instagramButton: file(name: { eq: "instagram-button" }) {
        childImageSharp {
          fluid(maxWidth: 528) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
    }
  `)

    return (
        <div data-aos="fade-up" style={{
            marginTop: 0,
            height: 280,
            backgroundColor: "#000",
        }}>
            <div sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                margin: `0 auto`,
                maxWidth: 960,
                position: "relative",
                padding: `0 1.0875rem 0`,
                height: "100%",
                "@media (max-width: 901px)": {
                    flexDirection: "column",
                }
            }}>

                <a href={data.site.siteMetadata.line} target={"_blank"} rel={"noopener noreferrer"} sx={{
                    display: "block",
                    width: "100%",
                    maxWidth: "528px",
                    marginRight: 33,
                    "@media (max-width: 901px)": {
                        maxWidth: 300,
                        marginRight: 0,
                        marginBottom: 36,
                    }
                }}>
                    <Img fluid={data.lineButton.childImageSharp.fluid} alt={"和風音 Line"}/>
                </a>
                <a href={data.site.siteMetadata.instagram} target={"_blank"} rel={"noopener noreferrer"} sx={{
                    display: "block",
                    width: "100%",
                    maxWidth: "528px",
                    "@media (max-width: 901px)": {
                        maxWidth: 300,
                    }
                }}>
                    <Img fluid={data.instagramButton.childImageSharp.fluid} alt={"和風音 Instagram"} />
                </a>
            </div>
        </div>
    )
}

export default RelatedAccounts
