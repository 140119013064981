import React from "react"
import {graphql, useStaticQuery} from "gatsby";
import Img from "gatsby-image";
import PropTypes from "prop-types";

const Feature = ({children, name, logoAlt, titleAlt, subTitle}) => {
    const data = useStaticQuery(graphql`
    query {
      traditionLogo: file(name: { eq: "tradition-logo" }) {
        childImageSharp {
          fluid(maxWidth: 708) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      traditionTitle: file(name: { eq: "tradition-title" }) {
        childImageSharp {
          fluid(maxWidth: 338) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }

      triggerLogo: file(name: { eq: "trigger-logo" }) {
        childImageSharp {
          fluid(maxWidth: 708) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      triggerTitle: file(name: { eq: "trigger-title" }) {
        childImageSharp {
          fluid(maxWidth: 338) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }

      suggestionLogo: file(name: { eq: "suggestion-logo" }) {
        childImageSharp {
          fluid(maxWidth: 708) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      suggestionTitle: file(name: { eq: "suggestion-title" }) {
        childImageSharp {
          fluid(maxWidth: 338) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
    }
  `)

    return (
        <div data-aos="fade-up" data-aos-delay="50">
            <div style={{ marginTop: 47 }}>
                <Img fluid={data[`${name}Logo`].childImageSharp.fluid} alt={logoAlt} style={{margin: "auto"}} />
                <p style={{
                    marginTop: 18,
                    letterSpacing: "4px",
                    color: "#fff",
                    fontSize: "18px",
                    textAlign: "center"
                }}>{logoAlt}</p>
            </div>
            <h3 style={{ marginTop: 21 }}>
                <p style={{
                    letterSpacing: "4px",
                    color: "#fff",
                    fontSize: "24px",
                    textAlign: "center",
                    lineHeight: 1.5
                }}>{titleAlt}</p>
                <p style={{
                    marginTop: 15,
                    letterSpacing: "4px",
                    color: "#fff",
                    fontSize: "18px",
                    textAlign: "center"
                }}>{subTitle}</p>
            </h3>
            <div style={{fontSize: 14, color: "#fff", maxWidth: 708, margin: "19px auto 0 auto", lineHeight: "21px"}}>
                <p>{children}</p>
            </div>
        </div>
    )
}

Feature.propTypes = {
    children: PropTypes.node.isRequired,
    name: PropTypes.string.isRequired,
    logoAlt: PropTypes.string.isRequired,
    titleAlt: PropTypes.string.isRequired,
    subTitle: PropTypes.string.isRequired,
}

export default Feature
