/** @jsx jsx */
import { jsx } from "theme-ui"
import React from "react"
import {graphql, Link, useStaticQuery} from "gatsby";
import Img from "gatsby-image";
import towaBackgroundImage from "../../images/towa-background.png"

const Towa = () => {
    const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          towaWebSiteUrl
          towaInstagram
        }
      }
      towaOfficial: file(name: { eq: "towa-official" }) {
        childImageSharp {
          fluid(maxWidth: 460) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      seeTowaOfficial: file(name: { eq: "see-towa-official" }) {
        childImageSharp {
          fluid(maxWidth: 256) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      towaInstagramButton: file(name: { eq: "towa-instagram-button" }) {
        childImageSharp {
          fluid(maxWidth: 528) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }

    }
  `)

    return (
        <div data-aos="fade-up" style={{marginBottom: 85}}>
            <div style={{
                marginTop: 0,
                height: 240,
                backgroundColor: "#000",
                backgroundSize: "cover",
                backgroundPosition: "center center",
                backgroundRepeat: "no-repeat",
                backgroundImage: `url(${towaBackgroundImage})`,
            }}>
                <a href={data.site.siteMetadata.towaWebSiteUrl} target={"_blank"} rel={"noopener noreferrer"}
                   style={{display: "inline-block", width: "100%", verticalAlign: "bottom", height: "100%"}}>
                    <div style={{
                    display: "flex",
                    alignItems: "center",
                    margin: `0 auto`,
                    maxWidth: 960,
                    position: "relative",
                    padding: `0 1.0875rem 0`,
                    height: "100%"
                }}>
                    <h2 style={{width: "100%"}}>
                        <Img fluid={data.towaOfficial.childImageSharp.fluid} alt={"TOWA Kyoto Of f icial site"} style={{margin: "auto"}} />
                    </h2>
                    <div style={{position: "absolute", bottom: 15, right: 24, width: 221}}>

                            <Img fluid={data.seeTowaOfficial.childImageSharp.fluid} alt={"公式サイトはこちらから"} />
                    </div>
                </div>
                </a>
            </div>
            <div style={{
                marginTop: 0,
                height: 260,
                backgroundColor: "#000",
            }}>
                <div sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    margin: `0 auto`,
                    maxWidth: 960,
                    position: "relative",
                    padding: `0 1.0875rem 0`,
                    height: "100%",
                    "@media (max-width: 901px)": {
                        flexDirection: "column",
                    }
                }}>
                    <a href={data.site.siteMetadata.towaInstagram} target={"_blank"} rel={"noopener noreferrer"} sx={{
                        display: "block",
                        width: "100%",
                        maxWidth: "444px",
                        "@media (max-width: 901px)": {
                            maxWidth: 300,
                        }
                    }}>
                        <Img fluid={data.towaInstagramButton.childImageSharp.fluid} alt={"和風音 Instagram"} />
                    </a>
                    <div id={"company"} />
                </div>
            </div>
        </div>
    )
}

export default Towa
