/** @jsx jsx */
import { jsx } from "theme-ui"
import React from "react";
import {graphql, useStaticQuery} from "gatsby";
import Img from "gatsby-image";

const Concept = () => {
    const data = useStaticQuery(graphql`
    query {
      newCulture: file(name: { eq: "new-culture" }) {
        childImageSharp {
          fluid(maxWidth: 777) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      synergy: file(name: { eq: "synergy" }) {
        childImageSharp {
          fluid(maxWidth: 333) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      pride: file(name: { eq: "pride" }) {
        childImageSharp {
          fluid(maxWidth: 630) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
    }
  `)

    return (
        <div id="concept" style={{
            paddingTop: 158
        }}>
            <h2 style={{ paddingTop: 36 }}>
                <Img fluid={data.newCulture.childImageSharp.fluid} alt={"新しいkimono文化の創造"} style={{margin: "auto"}} />
                <div sx={{ marginTop: 45,
                    "@media (max-width: 901px)": {
                        maxWidth: 280,
                        marginLeft: "auto",
                        marginRight: "auto"
                    }
                }}>
                    <Img fluid={data.synergy.childImageSharp.fluid} alt={"和の伝統文化の継承 x 次世代の着物文化"} style={{margin: "auto"}} />
                </div>
            </h2>
            <div style={{ marginTop: 105 }} data-aos="fade-up">
                <p style={{
                    marginBottom: 20,
                    letterSpacing: "4px",
                    color: "#fff",
                    fontSize: "18px",
                    textAlign: "center"
                }}>着物へのこだわり</p>
                <p style={{
                    letterSpacing: "4px",
                    color: "#fff",
                    fontSize: "24px",
                    textAlign: "center",
                    lineHeight: 1.5
                }}><span sx={{
                    "@media (max-width: 901px)": {
                        display: "block"
                    }
                }}>着物の「魅力」を</span>「人」に伝えるために。</p>
            </div>
            <div data-aos="fade-up" style={{fontSize: 14, color: "#fff", maxWidth: 708, margin: "19px auto 0 auto", paddingBottom: 10, lineHeight: "21px"}}>
                <p>モノや情報に溢れたこの時代、色や形は違えど、どんな店舗にも似たような商品が並んでいます。</p>
                <p>安くて、それなりの良さで、そこそこの満足感が得られる。決してそれが悪いことだとは思いません。</p>
                <p>しかし、それだけでは着物の魅力のすべてをお伝えすることはできないと考えています。</p>
                <p>和風音では、着物がもつ魅力を多くの人へ伝えられるよう、ものづくりへのこだわりはもちろん、お客様へと伝える手段にも、</p>
                <p>さらにはそれを活かしたライフスタイルの提案にもこだわりを持っています。</p>
            </div>
        </div>
    )
}

export default Concept
