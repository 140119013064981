import React from "react"

const Map = () => {
    return (
        <div data-aos="fade-up" style={{
            background: "#fff"
        }}>
            <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3269.749588331766!2d135.81227761523928!3d34.962884480368!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x60010e65c937e6b9%3A0x8e6e77187f883933!2z44CSNjA3LTgyNTMg5Lqs6YO95bqc5Lqs6YO95biC5bGx56eR5Yy65bCP6YeO5byT55Sw55S677yS77yR!5e0!3m2!1sja!2sjp!4v1601195956819!5m2!1sja!2sjp"
                width="100%" height="292" frameBorder="0" style={{border:0}} allowFullScreen="" aria-hidden="false"
                tabIndex="0" />

            <div style={{
                paddingTop: 34,
                paddingBottom: 100,
                textAlign: "center",
                color: "#646464",
            }}>
                [<a href={"https://www.google.com/maps/place/21+Onoyumidench%C5%8D,+Yamashina-ku,+Kyoto,+607-8253/@34.9628845,135.8122776,17z/data=!3m1!4b1!4m5!3m4!1s0x60010e65c937e6b9:0x8e6e77187f883933!8m2!3d34.9628845!4d135.8144663"}
                    target={"_blank"} rel={"noopener noreferrer"}
                    style={{
                        color: "#646464",
                        fontSize: 14
                    }}>
                より大きな地図をGoogleMapで開く
            </a>]
            </div>
        </div>
    )
}

export default Map
