import React from "react"
import {graphql, Link, useStaticQuery} from "gatsby";
import Img from "gatsby-image";
import orderBackgroundImage from "../../images/order-background.png"
import {jsx} from "theme-ui";

const Order = () => {
    const data = useStaticQuery(graphql`
    query {
      customMade: file(name: { eq: "custom-made" }) {
        childImageSharp {
          fluid(maxWidth: 683) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      contact: file(name: { eq: "contact" }) {
        childImageSharp {
          fluid(maxWidth: 221) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
    }
  `)

    return (
        <div data-aos="fade-up" style={{
            marginTop: 80,
            height: 280,
            backgroundColor: "#E02622",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundImage: `url(${orderBackgroundImage})`,

        }}>
            <Link to={"/contact"} style={{display: "inline-block", width: "100%", verticalAlign: "bottom", height: "100%"}}>
            <div style={{
                display: "flex",
                alignItems: "center",
                margin: `0 auto`,
                maxWidth: 960,
                position: "relative",
                padding: `0 1.0875rem 0`,
                height: "100%"
            }}>
                <h2 style={{width: "100%"}}>
                    <Img fluid={data.customMade.childImageSharp.fluid} alt={"あなただけの世界にひとつだけのオーダーメイド着物を作りませんか？"} style={{margin: "auto"}} />
                </h2>
                <div style={{position: "absolute", bottom: 15, right: 24, width: 221}}>
                        <Img fluid={data.contact.childImageSharp.fluid} alt={"お問い合わせはこちらから"} />
                </div>
            </div>
            </Link>
        </div>
    )
}

export default Order
