/** @jsx jsx */
import { jsx } from "theme-ui"
import React from "react"
import {graphql, useStaticQuery} from "gatsby";
import Img from "gatsby-image";

const Creator = () => {
    const data = useStaticQuery(graphql`
    query {
      creator: file(name: { eq: "creator" }) {
        childImageSharp {
          fluid(maxWidth: 176) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      name: file(name: { eq: "shuhei-ogawa-name" }) {
        childImageSharp {
          fluid(maxWidth: 375) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      photo: file(name: { eq: "shuhei-ogawa-photo" }) {
        childImageSharp {
          fluid(maxWidth: 240) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
    }
  `)

    return (
        <div data-aos="fade-up" className={""}>
            <div id={"creator"}  style={{ paddingTop: 100 }} />
            <div>
                <div style={{ marginBottom: 50 }}>
                    <Img fluid={data.creator.childImageSharp.fluid} alt={"creator"} />
                </div>
                <div sx={{
                    display: "flex",
                    "@media (max-width: 901px)": {
                        flexDirection: "column"
                    }

                }}>
                    <div sx={{
                        flex: "0 0 240px",
                        marginRight: 56,
                        "@media (max-width: 901px)": {
                            marginRight: 0,
                            marginBottom: 32
                        }
                    }}>
                        <Img fluid={data.photo.childImageSharp.fluid} alt={"和風音代表 小川 修平"} />
                    </div>
                    <div>
                        <div style={{marginBottom: 27}}>
                            <Img fluid={data.name.childImageSharp.fluid} alt={"KIMONO Artist Shuhei Ogawa"} />
                        </div>
                        <div style={{color: "#fff", fontSize: 14, lineHeight: 2}}>
                            <div style={{marginBottom: 19}}>
                                <p>現代において、なぜ着物は身近なもので無くなったか。</p>
                                <p>日本人として誇るべき「着物の伝統」を「次世代に繋げていく」為には何が必要なのか。</p>
                                <p>そのような疑問から私たちの着物への取り組みはスタートしました。</p>
                            </div>
                            <div style={{marginBottom: 19}}>
                                <p>着物は日本人にとって大切な伝統文化の象徴であるといえます。</p>
                                <p>しかしながら、その文化は少しずつ失われ、着物に触れる機会は確実に縮小していくでしょう。</p>
                                <p>この縮小に歯止めをかけるためには、着物の持つ良さを守りながら、若い世代に着物文化を広げていかなければならない。</p>
                                <p>そう強く感じています。</p>
                            </div>
                            <div style={{marginBottom: 19}}>
                                <p>時代の移り変わりとともに、変わらなければならないモノはたくさんありますが、着物に対する美しさ・憧れ・尊敬の想いは時代が変わっても、また世代が変わっても不変だと思います。</p>
                                <p>それは、私が着物デザイナーとして多くの方にお会いできて改めて実感いたしました。</p>
                                <p>街やパーティーなど様々な場面で着物姿を見かけた時に、誰もが感じる着物の良さは失われていません。</p>
                            </div>
                            <div style={{marginBottom: 19}}>
                                <p>ただ、これから次の世代に広げていくためには、もっと多くの努力が必要だとも感じています。</p>
                                <p>着物はその名前の通り、着て頂いて初めてその価値を発揮することができます。</p>
                                <p>若い世代に、また幅広い世代に身に着けて頂くために、価格や販売方法を多様化し、着付けや着こなしなどを提案することで、敷居の高さを感じさせない、親しみやすさも必要です。</p>
                            </div>
                            <div>
                                <p>伝統のある着物を、老若男女を問わず愛する事が出来る着物を届けられるよう、着物が持つ素晴らしさを世の中に広めていくことが私の使命だと考えています。</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Creator
