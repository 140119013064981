import React from "react"
import Feature from "./feature";

const Features = () => {

    return (
        <div style={{paddingTop: 12}}>
            <Feature name={"tradition"} logoAlt={"Tradition"} titleAlt={"伝統に基づいた技法"} subTitle={"伝統技法を使った着物作り"}>
                手描きの伝統を守りながら、かつオンリーワンの着物作りを目指しています。 一つ一つの着物から、大量生産にはない高い品質と、作り手の温もりを感じて頂きたいと思います。 着物のもつ美しさが伝わり、そして数十年と袖を通していくことを楽しみにできる着物をお作りしています。
            </Feature>

            <Feature name={"trigger"} logoAlt={"Trigger"} titleAlt={"和装にふれるきっかけ"} subTitle={"着物文化にふれるきっかけ作り"}>
                より多くの方に、和風音の着物に触れてもらえるような仕組み作りを目指しています。 全国の催事場はもちろん、従来の方法ににとらわれることなく、様々な場所で着物に触れて頂けるような機会の提供に取り組んでいます。 和風音が着物と日本の伝統を感じて頂くきっかけになれるように考えています。
            </Feature>

            <Feature name={"suggestion"} logoAlt={"Suggestion"} titleAlt={"斬新なアイディア"} subTitle={"着物ライフスタイルの提案"}>
                和風音は着物を手に取って、ただ単にお求め頂ければいいとは考えておりません。 これから着ていく方々に、その後のライフスタイルまでをご提案させて頂きます。 着てもらえる機会を提供することで、着物ライフを満喫してもらえればと思います。 着物は着て出掛けることで、最高の満足感を得る事が出来ると考えています。
            </Feature>
        </div>
    )
}

export default Features
