import React, {useEffect} from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import bodyBackgroundImage from "../images/body-background.png"
import Concept from "../components/top/concept";
import Features from "../components/top/features";
import Creator from "../components/top/creator";
import Wishes from "../components/top/wishes";
import Order from "../components/top/order";
import RelatedAccounts from "../components/top/related-accounts";
import Towa from "../components/top/towa";
import Company from "../components/top/company";
import Map from "../components/top/map";
import ReturnToTop from "../components/return-to-top";
import AOS from 'aos';
import 'aos/dist/aos.css';

const IndexPage = () => {
    useEffect(() => {
        AOS.init();
    }, []);

    return (
        <div style={{
            minHeight: "100vh",
            backgroundColor: "#000",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundImage: `url(${bodyBackgroundImage})`
        }}>
            <Layout isTop={true}>
                <SEO title="Home" />

                <div style={{
                    margin: `0 auto`,
                    maxWidth: 960,
                    padding: `0 1.0875rem 1.45rem`,
                }}>
                    <Concept />

                    <Features />

                    <Creator />

                    <Wishes />
                </div>

                <div>
                    <Order />

                    <RelatedAccounts />

                    <div style={{background: "#ffF"}}>
                        <Towa />

                        <Company />
                    </div>

                    <Map />

                    <ReturnToTop />
                </div>
            </Layout>
        </div>
    )
}

export default IndexPage
